.container {
  position: fixed;
  z-index: 10000;
  bottom: 2rem;
  left: 2rem;
  background-color: #fdfefd;
  padding: 2rem;
  border-radius: 1.25rem;
  box-shadow:  0px 0px 20px hsl(0, 0%, 0%, 20%);
  width: var(--width-480);
}

.container_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button {
  background-color: #668355;
  border: none;
  padding: 0.6rem 2rem;
  border-radius: 1.5625rem;
  color: #FDFEFD;
  box-shadow:  3px 3px 8px #bcbaba;
  transition: 0.4s ease;
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-wrap: wrap;
}

.button:hover {
  padding: 0.7rem 2.5rem;
  cursor: pointer;
  color: #FDFEFD;
  margin-bottom: 0;
}

.secondaireButton {
  background-color: transparent;
  border: solid 1px #668355;
  padding: 0.6rem 2rem;
  border-radius: 1.5625rem;
  color: #668355;
  transition: 0.4s ease;
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
}

.secondaireButton:hover {
  padding: 0.7rem 2.5rem;
  cursor: pointer;
  color: #668355;
  margin-bottom: 0;
}

@media (max-width: 500px) {
  .container {
    width: 15rem;
  }
}