.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container input {
  margin-top: 1rem;
  width: 15rem;
}

.button {
  background-color: #668355;
  border: none;
  padding: 0.6rem 2rem;
  border-radius: 1.5625rem;
  color: #FDFEFD;
  box-shadow:  3px 3px 8px #bcbaba;
  transition: 0.4s ease;
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-wrap: wrap;
}

.button:hover {
  padding: 0.7rem 2.5rem;
  cursor: pointer;
  color: #FDFEFD;
  margin-bottom: 0;
}