.inputContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.errorMessage {
  color: red;
  font-size: 0.8rem;
}

input, textarea {
  border: 1px solid #668355;
  border-radius: 1.25rem;
  padding: 0.6rem 1.5rem;
  box-shadow: inset 1px 1px 5px #bcbaba;
  background-color: transparent;
}

textarea {
  min-height: 5rem;
}

@media (max-width: 500px) {
  .inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .inputContainer {
    margin-bottom: 1.5rem;
  }

}
