@import "../src/Components/Variables/variables.css";

body {
  color: var(--color-text-dark);
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

img {
  border-radius: var(--border-radius);
}

header img, footer img {
  border-radius: 0 !important;
}



input, button, textarea {
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
}