.button {
  background-color: #668355;
  border: none;
  padding: 0.6rem 2rem;
  border-radius: 1.5625rem;
  color: #fff;
  box-shadow:  3px 3px 8px #bcbaba;
  transition: var(--transition);
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-wrap: wrap;
}

.button:hover {
  transform: scale(1.1);
  color: #fff;
}

.secondaireButton {
  background-color: transparent;
  border: solid 1px #668355;
  padding: 0.6rem 2rem;
  border-radius: 1.5625rem;
  color: #668355;
  transition: var(--transition);
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
}

.secondaireButton:hover {
  transform: scale(1.1);
  color: #668355;
}