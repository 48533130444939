:root {
  /* colors */
  --color-primary: #465045;
  --color-secondary: #F23030;

  --color-btn: #191918;

  --color-background-light: #FDFEFD;

  --color-border: hsl(0, 0%, 0%, 10%);

  --color-text-dark: #000;
  --color-text-light: #fff;
  

  /* spacing */
  --spacing-small: .625rem;
  --spacing-medium: 1rem;
  --spacing-large: 1.5rem;
  --spacing-xlarge: 2rem;
  --spacing-xxlarge: 3rem;
  --spacing-xxxlarge: 4rem;

  
  /* other */
  --border-radius: 1.25rem;
  --transition: 0.5s;
  --box-shadow: 0px 0px 10px hsl(0, 0%, 0%, 10%);

  /* responsive */
  --width-standart: 90rem;
  --width-1600: 86.25rem;
  --width-1400: 73.75rem;
  --width-1200: 60rem;
  --width-992: 46.25rem;
  --width-768: 36.25rem;
  --width-600: 28.75rem;
  --width-480: 20rem;
}