header {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 100;
}

.scrolled {
  background-color: #FDFEFD;
  transition: 0.3s ease;
  box-shadow:  0px 0px 20px hsl(0, 0%, 0%, 20%);
}

.logo {
  width: 6rem;
  height: auto;
}

a {
  text-decoration: none;
  color: var(--color-text-dark);
  transition: 0.2s ease;
}

a:hover {
  color: #7F9D69;
  cursor: pointer;

}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90rem;
  padding: 1rem;
}

.navRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 26rem;
}

.activeNav {
  color: #668355;
  font-weight: 800;
}

.greenBackground {
  background-color: #F4F8F1;
}

.hamburger, .close {
  display: none;
}

/* responsive */
@media (max-width: 1600px) {
  nav {
    width: var(--width-1600);
  }
}

@media (max-width: 1400px) {
  nav {
    width: var(--width-1400);
  }
}

@media (max-width: 1200px) {
  nav {
    width: var(--width-1200);
  }
}

@media (max-width: 992px) {
  nav {
    width: var(--width-992);
  }
}

@media (max-width: 768px) {
  nav {
    width: var(--width-768);
  }
}

@media (max-width: 600px) {
  nav {
    width: var(--width-600);
  }

  .hamburger, .close {
    display: flex;
  }

  .navRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: #FDFEFD;
    position: fixed;
    transform: translateX(100%);
    top: 0;
    left: 0;
    padding-top: 5rem;
    overflow-y: auto;
    transition: var(--transition);
    box-sizing: border-box;
  }

  .navRight a {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
  }

  .showMenu {
    transform: translateX(0);
  }

  .hamburger {
    font-size: 1.8rem;
    background: none;
    border: none;
  }

  .close {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
  }
}

@media (max-width: 480px) {
  nav {
    width: var(--width-480);
  }
}