.container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  column-gap: var(--spacing-xxlarge);
  row-gap: var(--spacing-xlarge);
}

.container_item {
  border-bottom: 1px solid #a3c585;
  padding-bottom: var(--spacing-xlarge);
}