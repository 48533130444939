.container {
    width: 100%;
}

.container h1 {
    font-size: 2.5rem;
    margin-top: 0;
    width: 100%;
}

.container h2 {
    font-size: 1.5625rem;
    margin-top: 2rem;
    margin-bottom: 1rem
}

.container p {
    margin-bottom: 1rem;
}

.container ol ul {
    margin-bottom: 1.5rem;
}

