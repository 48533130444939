.topSectoinAbout {
  display: grid;
  grid-template-columns: 60% auto;
  column-gap: var(--spacing-xxlarge);
}

.topSectionTextMargin {
  padding-bottom: 1rem;
}

.topSectionText p {
  margin-bottom: 1.5rem;
}

.topSectoinAbout img {
  width: 100%;
  height: auto;
}

.aboutMain {
  display: grid;
  grid-template-columns: 25% auto;
  column-gap: var(--spacing-xxlarge);
}

.aboutCards {
  display: grid;
  grid-template-columns: 100%;
  row-gap: var(--spacing-xxlarge);
  text-align: center;
  background-color: #F4F8F1;
  border-radius: 1.25rem;
  box-shadow: 3px 3px 10px #e3e3e3;
  box-sizing: border-box;
  padding: var(--spacing-large);
}

.aboutCards div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.aboutCards span {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 4rem;
  height: 4rem;
  background-color: #668355;
  border-radius: 100%;
  margin-bottom: 0.5rem;
}

.aboutText div {
  margin-bottom: 3rem;
}

.aboutTextFirst {
  margin-bottom: 3rem;
}

.aboutTextItalic {
  font-style: italic;
  margin-top: 1rem;
}


@media (max-width: 1200px) {
  .aboutMain {
    grid-template-columns: 30% auto;
    row-gap: var(--spacing-xxlarge);
  }

}


@media (max-width: 992px) {
  .topSectoinAbout,
  .aboutMain {
    grid-template-columns: 100%;
    row-gap: var(--spacing-xxlarge);
  }
  
  .aboutCards {
    grid-template-columns: calc(50% - var(--spacing-xxlarge) / 2) calc(50% - var(--spacing-xxlarge) / 2);
    column-gap: var(--spacing-xxlarge);
    row-gap: var(--spacing-xxlarge);
  }
  
  .topSectoinAbout img {
    order: -1;
    max-width: 20rem;
  }

}

@media (max-width: 600px) {
  .topSectoinAbout img {
    max-width: 100%;
  }

  .aboutCards {
    grid-template-columns: 100%;
  }
  

}
