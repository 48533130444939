footer {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #F4F8F1;
  margin-top: auto;
  position: relative;
  padding: 1rem 0;
}

.logo {
  width: 6rem;
  height: auto;
}

.footerAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--width-standart);
  padding: 1rem 0;
  border-bottom: #A3C585 solid 1px;
}

.footerMiddle {
  width: 26rem;
  display: flex;
  justify-content: space-between;
}

.copyright {
  color: #668355;
  font-size: 0.8rem;
  padding: 1rem 0;
}

.activeNav {
  color: #668355;
  font-weight: bold;
}

.bottomFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: var(--width-standart);
}

.bottomFooter a {
  font-size: 0.8rem;
  color: #668355;
}

.bottomFooter_policy {
  width: 15rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* responsive */
@media (max-width: 1600px) {
  .footerAlign,
  .bottomFooter {
    width: var(--width-1600);
  }
}

@media (max-width: 1400px) {
  .footerAlign,
  .bottomFooter {
    width: var(--width-1400);
  }
}

@media (max-width: 1200px) {
  .footerAlign,
  .bottomFooter {
    width: var(--width-1200);
  }
}

@media (max-width: 992px) {
  .footerAlign,
  .bottomFooter {
    width: var(--width-992);
  }
}

@media (max-width: 768px) {
  .footerAlign,
  .bottomFooter {
    width: var(--width-768);
  }
}

@media (max-width: 600px) {
  .footerAlign,
  .bottomFooter,
  .footerMiddle  {
    width: var(--width-600);
  }

  .footerAlign,
  .bottomFooter {
    display: flex;
    flex-direction: column;
  }

  .footerMiddle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem 0 1rem;
  }

  .footerMiddle a {
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .footerAlign,
  .bottomFooter,
  .footerMiddle  {
    width: var(--width-480);
  }
}