.card {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  box-shadow:  var(--box-shadow);
  overflow: hidden;
  width: 100%;
  transition: 0.4s ease;
}

.card:hover {
  transform: scale(1.05);
}

.cardImg {
  height: fit-content;
  max-height: 10rem;
  width: auto;
  object-fit: contain;
  border-radius: 0 !important;
}

.cardText {
  padding: 1.5rem;
  transition: 0.4s ease;
}

.card:hover .cardText {
  padding: 2rem;
}

.cardDate {
  font-size: 0.9rem;
  color: #7F9D69;
  margin-top: 1rem;
}