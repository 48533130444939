.mySwiper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78rem;
  margin-top: 4rem;
}

.swiperSlide {
  display: flex !important;
  flex-direction: row;
  box-shadow:  3px 3px 8px #bcbaba;
  border-radius: 1.25rem;
  overflow: hidden;
  margin-bottom: 3rem;
  margin-left: 1rem;
  width: 76.25rem !important;
}

.swiperSlideText {
  padding: 3rem;
  width: 40%;
}

.swiperSlideText p {
  margin-bottom: 2rem;
}

.swiperSlideImg {
  overflow: hidden;
  width: 60%;
}

.swiperSlideImg img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.sectionFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blogCards {
  display: grid;
  grid-template-columns: repeat(3, calc(33.3% - var(--spacing-large) / 1.5));
  column-gap: var(--spacing-large);
  row-gap: var(--spacing-large);
}

.titleBlog {
  align-self: start;
}

@media (max-width: 992px) {
  .swiperSlide {
    display: flex !important;
    flex-direction: column;
  }

  .swiperSlideText {
    width: auto;
  }

  .swiperSlideImg {
    overflow: hidden;
    width: 100%;
    order: -1;
  }
  
  .swiperSlideImg img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }

  .blogCards {
    grid-template-columns: repeat(2, calc(50% - var(--spacing-large) / 2));
  }

  .marginButton {
    margin-top: 0;
  }
}

@media (max-width: 600px) {
  .blogCards {
    grid-template-columns: 100%;
  }
}