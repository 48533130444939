.home {
  position: relative;
  top: -5rem;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F4F8F1;
  height: 85vh;
  width: 100vw !important;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.hero_img{
  height: 95%;
  width: 100%;
  position: absolute;
  bottom: 0;
  object-fit: cover;
}

h1 {
  font-size: 3.6rem;
  font-family: "Roboto", sans-serif;
  color: #668355;
  width: 60%;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.heroText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 85vh;
  width: 90rem;
  z-index: 1000;
}

.heroText_badge {
  height: fit-content;
}

.heroText_badge img {
  height: 5rem;
  width: auto;
    margin-top: var(--spacing-small);
}

.heroText p {
  font-size: 1.25rem;
  font-style: italic;
}

.whyMovimText {
  margin-bottom: 1.5rem;
}

.diensten {
  display: grid;
  grid-template-columns: calc(32.8% - var(--spacing-xlarge) / 2) calc(32.8% - var(--spacing-xlarge) / 2) calc(32.8% - var(--spacing-xlarge) / 2);
  column-gap: var(--spacing-xlarge);
  margin-top: var(--spacing-xxxlarge);
}

.diensten_item {
  display: grid;
  grid-template-columns: 20% auto;
  column-gap: var(--spacing-small);
  align-items: center;
  background-color: #F4F8F1;
  width: 100%;
  border-radius: 4rem;
  box-shadow:  3px 3px 10px #bcbaba;
  box-sizing: border-box;
}

.diensten_item_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 100%;
  background-color: #668355;
  color: #FDFEFD;
  box-sizing: border-box;
}

.diensten_item p {
  padding: var(--spacing-small) var(--spacing-medium);
  padding-left: 0;
}

.grid {
  display: grid;
  grid-template-columns: calc(50% - var(--spacing-xxlarge) / 2) calc(50% - var(--spacing-xxlarge) / 2);
  column-gap: var(--spacing-xxlarge);
  row-gap: var(--spacing-xxxlarge);
  padding-bottom: 1rem !important;
}

.grid img {
  width: 100%;
  height: auto;
}

.grid_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/* responsive */
@media (max-width: 1600px) {
  .heroText {
    width: var(--width-1600);
  }
}

@media (max-width: 1400px) {
  .heroText {
    width: var(--width-1400);
  }
}


@media (max-width: 1200px) {
  .heroText {
    width: var(--width-1200);
  }

  .grid img {
    align-self: center;
  }

}

@media (max-width: 992px) {

  h1 {
    font-size: 5rem;
    width: 100%;
    margin-top: 0vh;
  }

  .heroText {
    width: var(--width-992);
    height: 100%;
  }

  .hero_img {
    display: none;
  }

  .diensten {
    grid-template-columns: calc(50% - var(--spacing-xlarge) / 2) calc(50% - var(--spacing-xlarge) / 2);
    row-gap: var(--spacing-xlarge);
  }

  .grid {
    grid-template-columns: 100%;
  }

  .grid img:nth-of-type(1) {
    grid-row: 1;
  }

  .grid_text:nth-of-type(1) {
    grid-row: 2;
  }

  .grid img:nth-of-type(2) {
    grid-row: 3;
  }

  .grid_text:nth-of-type(2) {
    grid-row: 4;
  }
}

@media (max-width: 768px) {
  .heroText {
    width: var(--width-768);
  }

}


@media (max-width: 600px) {

  h1 {
    font-size: 3.5rem;
  }

  .heroText {
    width: var(--width-600);
  }

  .diensten {
    grid-template-columns: 100%;
  }

}


@media (max-width: 480px) {
  .heroText {
    width: var(--width-480);
  }
}
