.section {
  width: var(--width-standart);
  padding-top: 4rem;
  padding-bottom: 4rem;
}

/* responsive */
@media (max-width: 1600px) {
  .section {
    width: var(--width-1600);
  }
}

@media (max-width: 1400px) {
  .section {
    width: var(--width-1400);
  }
}

@media (max-width: 1200px) {
  .section {
    width: var(--width-1200);
  }
}

@media (max-width: 992px) {
  .section {
    width: var(--width-992);
  }
}

@media (max-width: 768px) {
  .section {
    width: var(--width-768);
  }
}

@media (max-width: 600px) {
  .section {
    width: var(--width-600);
  }
}

@media (max-width: 480px) {
  .section {
    width: var(--width-480);
  }
}