.greenBackground {
  background-color: #F4F8F1;
}

.sectionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sectionRow a {
  text-decoration: underline;
}

.contact {
  width: 32%;
}

.titleContact {
  margin-bottom: 3rem;
}

.contactInfo {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.6rem;
  align-items: center;
}

.contactInfo p, .contactInfo a {
  margin-left: 1rem;
}

.whiteTitle {
  margin-bottom: 0.8rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.form span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.marginInput {
  margin-right: 1.5rem;
}

.voorwaarden {
  margin-top: 0.5rem;
  font-size: .8rem;
}

.error {
  color: red;
  font-size: 0.8rem;
}

@media (max-width: 820px) {
  .sectionRow {
    display: flex;
    flex-direction: column;
  }

  .contact {
    width: 100%;
    margin-bottom: 4rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .form span {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .marginInput {
    margin-right: 0;
  }
}